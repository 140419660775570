<template>
  <Mastermind />
</template>

<script>
import Mastermind from "./components/Mastermind.vue"

export default {
  name: 'App',
  components: {
    Mastermind
  }
}
</script>

<style>
@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css";

.pointer {
  cursor: pointer;
}
</style>
