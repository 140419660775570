<template>
    <div class="columns mt-2">
        <div class="column is-half is-offset-one-quarter has-text-centered">
            <button class="button is-info is-light" @click="init()">Start</button>
        </div>
    </div>
    <div class="columns mt-2" v-if="started">
        <div class="column is-one-quarter is-offset-one-quarter">
            <table class="table is-bordered">
                <thead>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(at, idx) in attempts" :key="idx">
                        <td v-for="(icon, ix) in at" :key="ix">
                            <span class="icon pointer is-size-3">
                                <i :class="icon.cssClass"></i>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="column is-one-quarter has-text-centered">
            <div>
                <span class="icon pointer is-size-3 mr-5" v-for="(icon, idx) in icons" :key="idx">
                    <i :class="icon.cssClass" @click="addToAttempt(icon)"></i>
                </span>
            </div>
            <hr>
            <div class="mt-5">
                <span class="icon pointer is-size-3 mr-5" v-for="(icon, idx) in attempt" :key="idx">
                    <i :class="icon.cssClass"></i>
                </span>
                <button 
                    class="button is-primary ml-5" 
                    v-if="parseInt(attempt.length) === 4"
                    @click="guess()">
                    Confirm
                </button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Mastermind',
    data: function() {
        return {
            icons: [
                {value: 1, cssClass: "fas fa-ankh has-text-warning"},
                {value: 2, cssClass: "fas fa-apple-alt has-text-success"},
                {value: 3, cssClass: "fas fa-heart has-text-danger"},
                {value: 4, cssClass: "fas fa-cat has-text-primary"},
                {value: 5, cssClass: "fas fa-child has-text-success-dark"},
                {value: 6, cssClass: "fas fa-bone has-text-warning-dark"},
            ],
            started: false,
            combination: [],
            attempts: [],
            attempt: []
        };
    },
    methods: {
        init() {
            this.started = true;
            this.attempt = [];
            this.attempts = [];
            this.combination = [];
            this.createCombination();
        },
        createCombination() {
            for (let index = 0; index < 4; index++) {
                this.combination.push(this.icons[Math.floor(Math.random() * 6)]);
            }
            console.log(this.combination);
        },
        addToAttempt(icon) {
            if(parseInt(this.attempt.length) < 4) {
                this.attempt.push(icon);
            }
        },
        guess(){
            let startingIndexes = [];
            let match = 0;
            let exact = 0;
            for (let index = 0; index < this.attempt.length; index++) {
                const icon = this.attempt[index];
                let startingIndex = typeof(startingIndexes[icon.value]) !== 'undefined' ? 
                    startingIndexes[icon.value] + 1 : 0;
                if(this.combination.indexOf(icon, startingIndex) > - 1){ 
                    let combinationIndex = this.combination.indexOf(icon, startingIndex);
                    console.log(combinationIndex);
                    startingIndexes[icon.value] = combinationIndex;
                    this.attempt[combinationIndex].value === this.combination[combinationIndex].value ? 
                        exact++ : match++;
                }
                console.log(startingIndexes);
            }
            this.attempt.push({
                value: 9,
                cssClass: "fas fa-grip-lines-vertical"
            })
            this.displayExact(exact);
            this.displayMatched(match);
            this.attempts.push(this.attempt);
            this.attempt = [];
        },
        displayExact(num) {
            for (let index = 0; index < num; index++) {
                this.attempt.push({
                    value: 7,
                    cssClass: "fas fa-circle has-text-danger"
                });              
            }
        },
        displayMatched(num) {
            for (let index = 0; index < num; index++) {
                this.attempt.push({
                    value: 8,
                    cssClass: "fas fa-circle has-text-warning"
                });           
            }
        }
    }
}
</script>
<style scoped>

</style>